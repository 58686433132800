// src/templates/Contact/FromRFQ.js
import React, { useState } from 'react';
import FormInput from "../../components/UI/Input";

const ContactDemoPage = () => {
    const [formData, setFormData] = useState({
        company_name: '',
        contact_name: '',
        email: '',
        phone_number: '',
        job_title: '',
        insurance_type: '',
        company_size: '',
        additional: '',
        subject: 'Demonstration Request Form'  // Default subject
    });

    const [statusMessage, setStatusMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
         setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => { // Declared as 'async'
        e.preventDefault();

        // Optional: Add more robust validation here

        setIsSubmitting(true);
        setStatusMessage('');
         
        try {
                //  synchronize with .env.production environment variables
            const { REACT_APP_BACKEND_URL } = process.env;
            console.log('/DEMO REACT URL:', REACT_APP_BACKEND_URL);
            const response = await fetch(`${REACT_APP_BACKEND_URL}/send-email`, 
            {   // Ensure REACT_APP_BACKEND_URL is set
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const result = await response.json();
            if (response.ok) {
               setStatusMessage(result.message);
                    // Reset form
                setFormData({
                    company_name: '',
                    contact_name: '',
                    email: '',                    
                    phone_number: '',
                    job_title: '',                    
                    insurance_type: '',
                    company_size: '',
                    additional: '',
                    subject: 'Demonstration Request Form'
                });
            } else {
                // If backend returns validation errors
                if (result.errors) {
                    // Assuming 'errors' is an array of error messages
                    const errorMessages = result.errors.map(err => err.msg).join(' ');
                    setStatusMessage(errorMessages);
                } else {
                    setStatusMessage(result.error || 'handleSubmit - Failed to send the message. Please try again later.');
                }
            }
        } catch (error) {
            console.error('mpahome[contactDemo] handleSubmit - Error:', error);
            setStatusMessage('handleSubmit - An error occurred while sending the message.');
        } finally {
            setIsSubmitting(false);
        }
    };
        //  return jsx
    return (
        <div className="contact-form-wrap">
            <form id="contact-form" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'company_name'}
                            placeholder={'Company Name *'}
                            classes={'form-control'}
                            value={formData.company_name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'contact_name'}
                            placeholder={'Contact Name *'}
                            classes={'form-control'}
                            value={formData.contact_name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'phone_number'}
                            placeholder={'Phone Number'}
                            classes={'form-control'}
                            value={formData.phone_number}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'email'}
                            name={'email'}
                            placeholder={'Email *'}
                            classes={'form-control'}
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'job_title'}
                            placeholder={'Job Title'}
                            classes={'form-control'}
                            value={formData.job_title}
                            onChange={handleChange}                            
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            tag={'select'}
                            name={'insurance_type'}
                            placeholder={'Insurance Type'}
                            classes={'form-control'}
                            value={formData.insurance_type}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select insurance type *</option>
                            <option value="medicaid">Medicaid</option>
                            <option value="medicare">Medicare</option>
                            <option value="dsnp">DSNP</option>
                            <option value="commercial">Commercial</option>
                            <option value="other">Other</option>
                        </FormInput>
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            tag={'select'}
                            name={'company_size'}
                            placeholder={'Company Size'}
                            classes={'form-control'}
                            value={formData.company_size}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select company size *</option>
                            <option value="1-50">1-50 employees</option>
                            <option value="51-200">51-200 employees</option>
                            <option value="201-500">201-500 employees</option>
                            <option value="501-1000">501-1000 employees</option>
                            <option value="1000+">1000+ employees</option>
                        </FormInput>
                    </div>

                    <div className="col-12">
                        <FormInput
                            tag={'textarea'}
                            name={'additional'}
                            placeholder={'Additional'}
                            classes={'form-control'}
                            value={formData.additional}
                            onChange={handleChange}
                        />
                       <input type="hidden" name="subject" value={formData.subject} />
                       <FormInput
                            tag={'button'}
                            type={'submit'}
                            classes={'btn-outline'}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Sending...' : 'Send Message'}
                        </FormInput>

                        {statusMessage && <p className="status-message">{statusMessage}</p>}

                        <div className="form-message" />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ContactDemoPage;