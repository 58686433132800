import React from 'react';
import ContactRFQPage from "./ContactRFQPage";
// import ContactInfo from "./ContactInfo";

const ContactPage = () => {
    return (
        <div className={'contact-page-area-wrapper sp-y'}>
            <div className="container">
                <div className="contact-content-wrap">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="contact-form-area contact-method">
                                <h3>Submit RFQ to MPAxxx</h3>
                                <ContactRFQPage/>
                            </div>
                        </div>

                     </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;