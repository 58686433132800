import React from 'react';
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import {Link} from "react-router-dom";
import Logo from '../../assets/img/logompa-light.png'

function Footer() {
    return (
        <footer className="footer-area sp-bottom">
            <div
                className="container"
                style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    paddingTop: '0px',
                    paddingRight: '25px',
                    paddingLeft: '25px',
                    paddingBottom: '0' // Removed bottom padding
                }}
            >                
                {/* First Row: Logo on the Left and Text on the Right */}
                <div
                    className="row mtn-40 footer-row"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center', // Vertically centers the content
                        width: '100%',
                        margin: '0',
                        padding: '0'
                    }}
                >
                    {/* Logo/Text and information links */}
                    <div className="col-lg-8" style={{ padding: '0' }}>
                        <div className="widget-item">
                            <div 
                                className="about-widget" 
                                style={{ display: 'flex', alignItems: 'center',flexWrap: 'wrap', width: '100%'  }}
                            >
                                <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                                    <img
                                        src={Logo}
                                        alt="Logo"
                                        style={{
                                            maxWidth: '150px',
                                            height: 'auto',
                                            marginRight: '20px' // Space between logo and text
                                        }}
                                    />
                                </Link>
                                <div style={{ flex: '1', overflow: 'hidden' }}>
                                    <Text style={{ margin: '0', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                        We provide a comprehensive service that develops, hosts, and manages specialized portals designed to meet the unique needs of healthcare insurance providers.
                                    </Text>
                                </div>

                             </div>
                        </div>
                    </div>
                    {/* Information Links */}
                    <div
                        className="col-lg-4 footer-links"
                    >
                        <Widget title="Information"  className="widget-title">
                            <List classes="widget-list">
                                <LI>
                                    <Link to={`${process.env.PUBLIC_URL + "/about"}`}>Our Company</Link>
                                </LI>
                                <LI>
                                    <Link to={`${process.env.PUBLIC_URL + "/services"}`}>Our Services</Link>
                                </LI>
                            </List>
                        </Widget>
                    </div>            
                </div>    

                {/* border */}
                <div className="row" style={{ textAlign: 'center', borderTop: '1px solid #ccc', marginTop: '0' }}>
                    <Text 
                        style={{ margin: '0', padding: '0', lineHeight: '1.0' }} // Added inline styles to remove extra space
                    />
                </div>

                {/* Middle Row: Three Rectangle PNG Images */}
                <div  className="footer-middle-row">
                    <div className="col-md-4">
                        <img src={require('../../assets/img/feature/hitrust_s.jpg')} alt="Hitrust certified" className="footer-image" />
                    </div>
                    <div className="col-md-4">
                        <img src={require('../../assets/img/feature/impact_s.jpg')} alt="Illinois Medicaid Program" className="footer-image" />
                    </div>
                    <div className="col-md-4">
                        <img src={require('../../assets/img/feature/mbe_s.jpg')} alt="Minority Business" className="footer-image" />
                    </div>
                </div>
        
                {/* Thin Bottom Row: Copyright */}
                <div className="row" style={{ textAlign: 'center', marginTop: '0' }}>
                    <Text 
                        classes="copyright-txt"
                        style={{ margin: '0', padding: '0', }} // Added inline styles to remove extra space
                    >
                        &copy; {new Date().getFullYear()} Midwest Pharmacy Associates, LLC. All Rights Reserved.
                    </Text>
                </div>
            </div>
        </footer>
    );
}

export default Footer;