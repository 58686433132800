// src/templates/Contact/ContactRFQPage.js
import React, { useState } from 'react';
import FormInput from "../../components/UI/Input";

const ContactRFQPage = () => {
    const [formData, setFormData] = useState({
        company_name: '',
        contact_name: '',
        email: '',
        phone_number: '',
        job_title: '',
        insurance_type: '',
        company_size: '',
        additional: '',
        subject: 'RFQ Request Form'  // Default subject
    });

    const [statusMessage, setStatusMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
         setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => { // Declared as 'async'
        e.preventDefault();
            // Optional: Add more robust validation here
        setIsSubmitting(true);
        setStatusMessage('');

        try {
                //  synchronize with .env.production environment variables
            const { REACT_APP_BACKEND_URL, BACKEND_API_KEY } = process.env;
            console.log('/RFQ REACT URL:', REACT_APP_BACKEND_URL, BACKEND_API_KEY);

            const response = await fetch(`${REACT_APP_BACKEND_URL}/send-email`, 
            {                
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            const result = await response.json();
            if (response.ok) {
                setStatusMessage(result.message);
                    // Reset form
                setFormData({
                    company_name: '',
                    contact_name: '',
                    email: '',
                    phone_number: '',
                    service_type: '',
                    project_scope: '',
                    expected_timeline: '',
                    budget: '',
                    specific_requirement: '',
                    additional: '',
                    subject: 'RFQ Request Form'
                });
            } else {
                // If backend returns validation errors
                if (result.errors) {
                    // Assuming 'errors' is an array of error messages
                    const errorMessages = result.errors.map(err => err.msg).join(' ');
                    setStatusMessage(errorMessages);
                } else {
                    setStatusMessage(result.error || 'handleSubmit - Failed to send the message. Please try again later.');
                }
            }
        } catch (error) {
            console.error('mpahome[contactRFQ] handleSubmit - Error:', error);
            setStatusMessage('handleSubmit - An error occurred while sending the message.');
        } finally {
            setIsSubmitting(false);
        }
    };
    
        //  return jsx
    return (
        <div className="contact-form-wrap">
            <form id="contact-form" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'company_name'}
                            placeholder={'Company Name *'}
                            classes={'form-control'}
                            value={formData.company_name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'contact_name'}
                            placeholder={'Contact Name *'}
                            classes={'form-control'}
                            value={formData.contact_name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'email'}
                            name={'email'}
                            placeholder={'Email *'}
                            classes={'form-control'}
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'phone_number'}
                            placeholder={'Phone Number'}
                            classes={'form-control'}
                            value={formData.phone_number}
                            onChange={handleChange}
                        />
                    </div>
 
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'project_scope'}
                            placeholder={'Project Scope'}
                            classes={'form-control'}
                            value={formData.project_scope}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6">
                    <FormInput  
                            tag={'select'}
                            name={'expected_timeline'}
                            placeholder={'Expected Timeline'}
                            classes={'form-control'}
                            value={formData.expected_timeline}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select expected timeline *</option>
                            <option value="immediate">Immediate</option>
                            <option value="1-3-months">1-3 months</option>
                            <option value="3-6-months">3-6 months</option>
                            <option value="6-12-months">6-12 months</option>
                            <option value="future">Future consideration</option>
                        </FormInput>
                   </div>
                   <div className="col-md-6">
                        <FormInput
                            tag={'select'}
                            name={'service_type'}
                            placeholder={'Service Type *'}
                            classes={'form-control'}
                            value={formData.service_type}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select service type</option>
                            <option value="benefit-management">Supplemental Benefit Management</option>
                            <option value="medicaid">Medicaid Solutions</option>
                            <option value="medicare">Medicare Solutions</option>
                            <option value="dsnp">DSNP Solutions</option>
                            <option value="commercial">Commercial Insurance Solutions</option>
                            <option value="other">Other</option>
                        </FormInput>
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            tag={'select'}
                            name={'budget'}
                            placeholder={'Budget Range *'}
                            classes={'form-control'}
                            value={formData.budget}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select budget range</option>
                            <option value="under-50k">Under $50,000</option>
                            <option value="50k-100k">$50,000 - $100,000</option>
                            <option value="100k-250k">$100,000 - $250,000</option>
                            <option value="250k-500k">$250,000 - $500,000</option>
                            <option value="over-500k">Over $500,000</option>
                      </FormInput>
                    </div>
                    <div className="col-md-6">
                        <FormInput
                                tag={'input'}
                                type={'text'}
                                name={'specific_requirement'}
                                placeholder={'Specific Requirement'}
                                classes={'form-control'}
                                value={formData.specific_requirement}
                                onChange={handleChange}
                        />
                    </div>

                    <div className="col-12">
                        <FormInput
                            tag={'textarea'}
                            name={'additional'}
                            placeholder={'Additional'}
                            classes={'form-control'}
                            value={formData.additional}
                            onChange={handleChange}
                        />
                        <input type="hidden" name="subject" value={formData.subject} />
                        <FormInput
                            tag={'button'}
                            type={'submit'}
                            classes={'btn-outline'}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Sending...' : 'Send Message'}
                        </FormInput>

                        {statusMessage && <p className="status-message">{statusMessage}</p>}

                        <div className="form-message" />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ContactRFQPage;
