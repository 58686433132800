import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

/*
 * @ All pages Import
 */
import HomeOne from './pages/HomeOne';
import HomeTwo from './pages/HomeTwo';
import Service from './pages/Service';
import ServiceDetails from "./pages/ServiceDetails";
import BlogGridRightSidebar from './pages/BlogGridRightSidebar';
import BlogGridLeftSidebar from './pages/BlogGridLeftSidebar';
import BlogGridWithoutSidebar from './pages/BlogGridWithoutSidebar';
import BlogListLeftSidebar from './pages/BlogListLeftSidebar';
import BlogListRightSidebar from './pages/BlogListRightSidebar';
import BlogDetailsPage from "./pages/BlogDetails";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Error404 from "./pages/Error404";
import ScrollToTop from "./helpers/ScrollToTop";

const App = () => {
    return (
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route path="/services" element={<Service />} />
                    <Route path="/service/:serviceID" element={<ServiceDetails />} />
                    <Route path="/blog-grid-right-sidebar" element={<BlogGridRightSidebar />} />
                    <Route path="/blog-grid-left-sidebar" element={<BlogGridLeftSidebar />} />
                    <Route path="/connectwithus" element={<BlogGridWithoutSidebar />} />
                    <Route path="/blog-list-left-sidebar" element={<BlogListLeftSidebar />} />
                    <Route path="/blog-list-right-sidebar" element={<BlogListRightSidebar />} />
                    <Route path="/blog/:blogID" element={<BlogDetailsPage />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/team-member/:teamID" element={<TeamDetails />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/home-one" element={<HomeOne />} />
                    <Route path="/home-two" element={<HomeTwo />} />
                    <Route path="/" element={<HomeOne />} />
                    <Route path="*" element={<Error404 />} />
                </Routes>
            </ScrollToTop>
        </Router>
    );
};

export default App;
