
import React, {Component} from 'react';
import logo from '../../assets/img/mpa-logo-x.png'

class Logo extends Component {
    render() {
        return (
            <div className="logo-area">
             
                <a href="/" target="_blank" rel="noopener noreferrer"><img src={logo} alt="mpa-Logo"/></a>

            </div>
        );
    }
}

export default Logo;
