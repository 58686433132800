import React,{Fragment} from 'react';
import PageWrapper from "../../components/PageWrapper";
import BlogDetailsContentWrapper from "./ContentWrapper";
import SidebarForBlog from "../SidebarForBlog";
// import PageHeader from "../../components/PageHeader";
import BlogData from "../../data/Blog/blog";
//
import ContactRFQPage from "../Contact/ContactRFQPage"; // Import the new ContactPage component
import ContactSalesPage from "../Contact/ContactSalesPage"; // Import the new ContactPage component
import ContactDemoPage from "../Contact/ContactDemoPage"; // Import the new ContactPage component

 
//
const BlogDetailsPage = ({sidebar}) => {
    const post_id = new URLSearchParams(window.location.search).get("id");
    const post = BlogData.find(post => post.id === parseInt(post_id));

        // Function to render the appropriate contact form based on post.id
    const renderContactForm = () => {
        if (!post) return null; // Handle the case where post is not found
        switch (post.id) {
            case 1:
                return <ContactSalesPage />;
            case 2:
                return <ContactDemoPage />;
            case 3:
                return <ContactRFQPage />;
            default:
                return null; // Or a default form/component
        }
    };

    return (
        <Fragment>
 
            <PageWrapper classes={'blog-details-page-content sp-yx'}>
                <div className={!sidebar ? 'col-12' : 'col-lg-9'}>
                    <BlogDetailsContentWrapper sidebar={sidebar} post={post}/>
                </div>

                {sidebar ? <SidebarForBlog/> : null}
            </PageWrapper>

             {/* Directly Embed the Wrapping Structure */}
             <div className={'contact-page-area-wrapper sp-y'}>
                <div className="container">
                    <div className="contact-content-wrap">
                        <div className="row">
                            <div className="col-lg-8">
                            <div className="contact-form-area contact-method">
                                    <h3>{post ? post.titleForm : 'Contact Us'}</h3>
                                    {renderContactForm()} {/* Render the selected form */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
        </Fragment>
    );
};

export default BlogDetailsPage;