
import React, {Component} from 'react';
import logo from '../../assets/img/Hitrust Logo-2.jpg'

class HiTrust extends Component {
    render() {
        return (
             <div className="logo-area">             
                <a href="https://https://hitrustalliance.net//" target="_blank" rel="noopener noreferrer"><img src={logo} alt=""/></a>
            </div>
        );
    }
}

export default HiTrust;