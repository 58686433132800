import React, {Fragment} from 'react';
import PageWrapper from "../../components/PageWrapper";
// import PageHeader from "../../components/PageHeader";
import BlogContent from "./BlogContent";
//import SidebarForBlog from "../SidebarForBlog";

const BlogPage = ({sidebar_position,blog_type,sidebar}) => {
    return (
        <Fragment>

            <PageWrapper classes={'blog-page-content-area sp-yx'} style={{ marginTop: '0px', paddintTop: '0px' }} >


                <BlogContent blog_type={blog_type} cols={sidebar ? 'col-lg-9' : 'col-12'} classes={sidebar_position === 'left' ? 'order-0 order-lg-1':null}/>


            </PageWrapper>
        </Fragment>
    );
};

export default BlogPage;