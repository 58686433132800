import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import About from "../components/About/home-two";
import Vision from "../components/About/vision";

// import BrandLogo from "../components/BrandLogo";
// import Funfact from "../components/Funfact";
// import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Features from "../components/Features";
// import Testimonial from "../components/Testimonials/home-two";
// import Team from "../components/Team/home-two";

import ServiceThumb from '../assets/img/about.jpg'



const PageAbout = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/mpa-aboutus-x.jpg')}
                title="ABOUT US"
                content="Midwest Pharmacy Associates, a minority-owned enterprise, is committed to enhancing our partners' quality of life through innovative healthcare solutions. We actively collaborate with our partners to optimize their benefit utilization, focusing on promoting healthy living and providing access to effective over-the-counter treatments. While firmly established in our current market, we are continuously expanding our reach to address the healthcare needs of underserved demographics and communities. Our mission extends beyond traditional pharmacy services; we strive to be a comprehensive solutions provider, dedicated to improving healthcare accessibility and outcomes for all. At Midwest Pharmacy Associates, we believe in the power of proactive health management and are passionate about making a meaningful impact in the lives of those we serve.
"
            />
            <Vision
                title={'Our Vision'}
                heading="Reimagining Healthcare"
                thumb={ServiceThumb}
                content="MPA's Vision: Pioneering integrated healthcare solutions that empower organizations, elevate patient well-being, and transform community health outcomes."
             />
            <About
                title={'Our Mission'}
                heading=""
                thumb={ServiceThumb}
                content="MPA's mission: To revolutionize healthcare through innovative digital solutions that optimize benefits, enhance user experience, and improve health outcomes for diverse communities."
             />
            <Features classes={'sm-top'}/>


            <Footer/>
            <LoginRegister/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageAbout;