import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import Services from "../components/Services";
//import PricingTable from "../components/PricingTable";
// import Testimonial from "../components/Testimonials/home-two";
//import BrandLogo from "../components/BrandLogo";
//import Funfact from "../components/Funfact";
//import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

//import ServiceThumb from '../assets/img/about.jpg'
//import ServiceThumb from '../assets/img/mpa-design-x.jpg'

const PageService = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/page-header.jpg')}
                title="OUR SERVICES"

            />
            <PageAbout
                title={'Our Services'}
                heading="Midwest Pharmacy Associates collaborates with healthcare providers to deliver tailored supplemental benefit management across Medicaid, Medicare, DSNP, and Commercial insurance plans."
               />
            <Services classes="sm-top"/>

   

            <Footer/>
            <LoginRegister/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageService;