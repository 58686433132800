// src/components/UI/Input.js
import React from 'react';
import PropTypes from 'prop-types';

const FormInput = ({
    tag,
    type,
    name,
    placeholder,
    classes,
    value,
    onChange,
    children, // For select options
    required,
}) => {
    return (
        <div className="single-input-item">
            <label>
                {(() => {
                    if (tag === 'input') {
                        return (
                            <input
                                type={type}
                                name={name}
                                placeholder={placeholder}
                                className={classes}
                                value={value}
                                onChange={onChange}
                                required={required}
                            />
                        );
                    } else if (tag === 'textarea') {
                        return (
                            <textarea
                                name={name}
                                cols="30"
                                rows="4"
                                placeholder={placeholder}
                                className={classes}
                                value={value}
                                onChange={onChange}
                                required={required}
                            />
                        );
                    } else if (tag === 'select') {
                        return (
                            <select
                                name={name}
                                className={classes}
                                value={value}
                                onChange={onChange}
                                required={required}
                            >
                                {children}
                            </select>
                        );
                    } else if (tag === 'button') {
                        return (
                            <button type="submit" className={`btn-outline ${classes}`}>
                                {children || 'Send Message'}
                            </button>
                        );
                    }
                })()}
            </label>
        </div>
    );
};

FormInput.propTypes = {
    tag: PropTypes.string.isRequired,
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    classes: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    children: PropTypes.node,
    required: PropTypes.bool,
};

FormInput.defaultProps = {
    type: 'text',
    placeholder: '',
    classes: '',
    value: '',
    onChange: () => {},
    children: null,
    required: false,
};

export default FormInput;
